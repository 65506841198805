import React, { useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import { useHeader } from "contexts/header"

import { SEO, Box, Flex, H1, RichText, Wrapper, Button } from "components"
import { usePreviewData } from "utils"

function ThankYouPage() {
  const data = useStaticQuery(
    graphql`
      {
        prismicThankYou {
          data {
            title {
              text
            }
            thank_you_description {
              html
            }
          }
        }
      }
    `
  )
  // Merge preview data with static query if possible
  const MERGED_DATA = usePreviewData(data)
  const { prismicThankYou } = MERGED_DATA

  const { setHeaderMode } = useHeader()

  useEffect(() => {
    setHeaderMode("solid")
  }, [])

  return (
    <>
      <SEO title={{ text: `Thank You | Partners Homes` }} />
      <Wrapper as="section" pt={[5, 6]}>
        <Box mb={4}>
          <H1
            children={
              prismicThankYou.data.title && prismicThankYou.data.title.text
            }
            mb={3}
          />
          <RichText content={prismicThankYou.data.thank_you_description} />
        </Box>
        <Flex flexWrap="wrap">
          <Box mr={2} mb={2}>
            <Button
              children={`Go back`}
              variant="default"
              onClick={() => {
                window.history.back()
              }}
            />
          </Box>
          <Box>
            <Button
              children={`See our communities`}
              variant="alt"
              as={Link}
              to="/communities/"
            />
          </Box>
        </Flex>
      </Wrapper>
    </>
  )
}

export default ThankYouPage
